import {FC, useRef, useState} from 'react'
import {
	Edit,
	ImageField,
	ImageInput,
	SimpleForm,
	TextInput,
	Toolbar,
	required,
	useNotify,
	useRedirect,
	useUpdate
} from 'react-admin'

import {LoadingButton} from '@mui/lab'
import {Alert, Button as MuiButton, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import {useFormContext} from 'react-hook-form'
import {UploadVideoSnippet, download, storageRef} from '../../lib/firebase'
import {uploadAndDownloadBlob} from './VolunteerTeamsCreate'
import {CoversField} from './VolunteerTeamsList'
import {ImageCropperModal} from "../../components/common/ImageCropperModal";

const CustomToolBar: FC<{
	logoSrc: string
	logoSrcEN: string
	cover: string
	coverEN: string
	logoBlob: Blob | null
	logoBlobEN: Blob | null
	blob: Blob | null
	blob_en: Blob | null
}> = ({
	      logoSrc,
	      logoSrcEN,
	      cover,
	      coverEN,
	      logoBlob,
	      logoBlobEN,
	      blob,
	      blob_en,
      }) => {
	const form = useFormContext()
	const notify = useNotify()
	const [update, {isLoading}] = useUpdate()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const data = form.getValues()
			const {
				id,
				name,
				name_en,
				description,
				description_en,
				cover_small,
				cover_small_en,
				cover_large,
				cover_large_en,
			} = data
			delete data.id

			const generateBannerString = () => {
				const id = Math.random().toString(36).substring(2, 12)
				return `Banner-${id}`
			}

			console.log("generateBannerString", generateBannerString)

			// Logo
			if (logoBlob) {
				const snapshot = await UploadVideoSnippet(
					generateBannerString(),
					logoBlob
				)
				data.cover_small = await download(
					storageRef(snapshot.metadata.fullPath)
				)
			}

			if (logoBlobEN) {
				const snapshot = await UploadVideoSnippet(
					generateBannerString(),
					logoBlobEN
				)
				data.cover_small_en = await download(
					storageRef(snapshot.metadata.fullPath)
				)
			}

			// Cover
			if (blob) {
				const snapshot = await UploadVideoSnippet(
					generateBannerString(),
					blob
				)
				data.cover_large = await download(
					storageRef(snapshot.metadata.fullPath)
				)
			}

			if (blob_en) {
				const snapshot = await UploadVideoSnippet(
					generateBannerString(),
					blob_en
				)
				data.cover_large_en = await download(
					storageRef(snapshot.metadata.fullPath)
				)
			}

			const blobArray = data.covers_description.filter((picture: {
				rawFile: File,
				src: string,
				title: string
			}) => picture.rawFile)
				.map((picture: { rawFile: File, src: string, title: string }) => picture.rawFile);

			let covers = [];

			for (let i = 0; i < blobArray.length; i++) {
				const cover = await uploadAndDownloadBlob(blobArray[i], blobArray[i].path);
				covers.push(cover);
			}

			console.log('data', data)

			await update('volunteer_teams', {
				id,
				data: {...data, covers_description: covers.length === 0 ? data.covers_description : covers}
			})
			redirect('/volunteer_teams')

		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
			<LoadingButton
				size='medium'
				loading={isLoading}
				variant="outlined"
				onClick={SaveHandler}
			>
				Зберегти
			</LoadingButton>
		</Toolbar>
	)
}

const VolunteerTeamsEdit = () => {
	const [openLogoModal, setOpenLogoModal] = useState(false);
	const [openLogoEnModal, setOpenLogoEnModal] = useState(false);

	const [logoSrc, setLogoSrc] = useState('')
	const [logoSrcEN, setLogoSrcEN] = useState('')
	const [cover, setCover] = useState('')
	const [coverEN, setCoverEN] = useState('')

	const [logoBlob, setLogoBlob] = useState<Blob | null>(null)
	const [logoBlobEN, setLogoBlobEN] = useState<Blob | null>(null)

	const [blob, setBlob] = useState<Blob | null>(null)
	const [blobEN, setBlobEN] = useState<Blob | null>(null)

	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)
	const ImageInputRefEN = useRef<HTMLInputElement>(null)

	const LogoInputRef = useRef<HTMLInputElement>(null)
	const LogoInputRefEN = useRef<HTMLInputElement>(null)

	const [isBanner, setIsBanner] = useState(false)

	// const ImageInputRef = useRef<HTMLInputElement>(null)
	// const ImageInputRefEN = useRef<HTMLInputElement>(null)

	const handleUploadLogo = (event: any) => {
		setBlob(null)
		setBlobError('')

		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setLogoSrc(img.src)
			setOpenLogoModal(true)
			setLogoBlob(event.target.files[0])
		}
	}

	const handleUploadLogoEN = (event: any) => {
		setBlobEN(null)
		setBlobError('')

		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setLogoSrcEN(img.src)
			setOpenLogoEnModal(true)
			setLogoBlobEN(event.target.files[0])
		}
	}

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setCover(img.src)
			setBlob(event.target.files[0])
			setBlobError('')
		}
	}

	const handleUploadEN = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setCoverEN(img.src)
			setBlobEN(event.target.files[0])
			setBlobError('')
		}
	}

	return (
		<Edit title='Редагувати команду' sx={{tableLayout: 'fixed'}}>
			<SimpleForm
				toolbar={
					<CustomToolBar
						logoSrc={logoSrc ?? null}
						logoSrcEN={logoSrcEN}
						cover={cover}
						coverEN={coverEN}
						logoBlob={logoBlob}
						logoBlobEN={logoBlobEN}
						blob={blob}
						blob_en={blobEN}
					/>
				}
			>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Українською'
						source='name'
					/>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Англійською'
						source='name_en'
					/>
				</Box>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Українською'
						source='description'
					/>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Англійською'
						source='description_en'
					/>
				</Box>

				{/*Logos*/}
				<Box
					display='flex'
					flexDirection='row'
					width='100%'
					sx={{mt: 3, mb: 5}}
					alignItems='center'
					gap={3}
				>
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{display: 'none'}}
							ref={LogoInputRef}
							onChange={handleUploadLogo}
						/>
						{logoBlob ? (
							<>
								<ImageCropperModal open={openLogoModal} setOpen={setOpenLogoModal} selectedFile={logoBlob}
								                   setBlob={setLogoBlob}/>
								<img
									style={{width: '100%', height: '100%'}}
									src={URL.createObjectURL(logoBlob)}
									alt={''}
								/>
							</>
						) : (
							<ImageField
								sx={{
									'& .RaImageField-image': {
										margin: 0,
										width: '100%',
										height: '100%',
									},
								}}
								title='video-snippet'
								source='cover_small'
							/>
						)}

						{blobError && (
							<Alert severity='error' sx={{mb: 2}}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (LogoInputRef.current) {
										LogoInputRef.current.click()
									}
								}}
							>
								Змінити лого
							</MuiButton>
						</Box>
					</Box>

					{/*Logo EN*/}
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{display: 'none'}}
							ref={LogoInputRefEN}
							onChange={handleUploadLogoEN}
						/>
						{logoBlobEN ? (
							<>
								<ImageCropperModal open={openLogoEnModal} setOpen={setOpenLogoEnModal} selectedFile={logoBlobEN}
								                   setBlob={setLogoBlobEN}/>
								<img
									style={{width: '100%', height: '100%'}}
									src={URL.createObjectURL(logoBlobEN)}
									alt={''}
								/>
							</>
						) : (
							<ImageField
								sx={{
									'& .RaImageField-image': {
										margin: 0,
										width: '100%',
										height: '100%',
									},
								}}
								title='video-snippet'
								source='cover_small_en'
							/>
						)}

						{blobError && (
							<Alert severity='error' sx={{mb: 2}}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								fullWidth
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (LogoInputRefEN.current) {
										LogoInputRefEN.current.click()
									}
								}}
							>
								Змінити лого EN
							</MuiButton>

						</Box>
					</Box>
				</Box>

				<ImageInput helperText="Завантажуй зображення" source="covers_description" accept="image/*" multiple label="">
					<ImageField source="src" title="title"/>
				</ImageInput>
				<CoversField label="Фото"/>
				<Box>
					<Typography
						sx={{
							fontSize: 14,
						}}
					>
						Зображення повинно мати 534x356 пікселів
					</Typography>
				</Box>

			</SimpleForm>
		</Edit>
	)
}

export default VolunteerTeamsEdit
