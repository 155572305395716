import {FC, useRef, useState} from 'react'
import {
	BooleanInput,
	Button,
	Create,
	DateTimeInput,
	required,
	SimpleForm,
	TextInput,
	Toolbar,
	useCreate,
	useNotify,
	useRedirect,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'

import {Alert, Button as MuiButton, Typography} from '@mui/material'
import Box from '@mui/material/Box'

import {download, storageRef, UploadVideoSnippet} from '../../lib/firebase'
import {ImageCropperModal} from "../../components/common/ImageCropperModal";

const CustomToolBar: FC<{ blob: Blob | null; blobEn: Blob | null }> = ({
	                                                                       blob,
	                                                                       blobEn,
                                                                       }) => {
	const form = useFormContext()
	const [create, {isLoading}] = useCreate()

	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const values = form.getValues()
			console.log('values', values)

			let cover
			let cover_en

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					values.name?.replace(/\s/g, ''),
					blob
				)
				cover = await download(storageRef(snapshot.metadata.fullPath))
			}

			if (blobEn) {
				const snapshot = await UploadVideoSnippet(
					values.name_en?.replace(/\s/g, ''),
					blobEn
				)
				cover_en = await download(storageRef(snapshot.metadata.fullPath))
			}

			const data = {
				name: values.name,
				name_en: values.name_en,
				cover,
				cover_en: cover_en,
				description: values.description,
				description_en: values.description_en,
				link: values.link,
				date: values.date,
				shown: values.shown,
				video_id: values.video_id,
			}

			await create('events', {data})
			redirect('/events')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const EventsCreate = () => {
	const notify = useNotify()

	const [open, setOpen] = useState(false);
	const [openEn, setOpenEn] = useState(false);

	const [blob, setBlob] = useState<Blob | null>(null)
	const [blobEN, setBlobEN] = useState<Blob | null>(null)

	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)
	const ImageInputRefEN = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		setBlob(null);
		setBlobError('');

		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setBlobError('')
			setOpen(true)
			setBlob(event.target.files[0])
		}
	}

	const handleUploadEN = (event: any) => {
		setBlobEN(null);
		setBlobError('');

		const img = new Image();
		img.src = URL.createObjectURL(event.target.files[0]);

		img.onload = () => {
			setBlobError('');
			setOpenEn(true);
			setBlobEN(event.target.files[0]);
		};
	};

	return (
		<Create title='Створити нову подію' sx={{tableLayout: 'fixed'}}>
			<SimpleForm toolbar={<CustomToolBar blob={blob} blobEn={blobEN}/>}>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Українською'
						source='name'
					/>
					<TextInput
						fullWidth
						validate={required()}
						label='Назва Англійською'
						source='name_en'
					/>
				</Box>
				<Box display='flex' flexDirection='row' width='100%' gap={5}>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Українською'
						source='description'
					/>
					<TextInput
						multiline
						fullWidth
						validate={required()}
						label='Опис Англійською'
						source='description_en'
					/>
				</Box>
				<Box display='flex' flex='row' alignItems="center" width='100%'>
					<BooleanInput label='Показується' source='shown'/>
					<DateTimeInput validate={required()} label='Дата проведення' source='date'/>
				</Box>
				<Box display='flex' flexDirection='row' width='100%'>
					<TextInput
						fullWidth
						label='Link'
						source='link'
					/>
				</Box>

				<Box display='flex' flexDirection='column' width='100%'>
					<h4>Поля для застосунку</h4>
					<TextInput
						fullWidth
						label='Відео ID'
						source='video_id'
					/>
				</Box>

				<Box
					display='flex'
					flex='row'
					width='100%'
					sx={{mt: 3}}
					alignItems='center'
					gap={3}
				>
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{display: 'none'}}
							ref={ImageInputRef}
							onChange={handleUpload}
						/>
						{open && <ImageCropperModal open={open} setOpen={setOpen} selectedFile={blob} setBlob={setBlob}/>}
						{blob && <img
							style={{width: '100%', height: '100%'}}
							src={URL.createObjectURL(blob)}
							alt='photo'
						/>}

						{blobError && (
							<Alert severity='error' sx={{mb: 2}}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (ImageInputRef.current) {
										ImageInputRef.current.click()
									}
								}}
							>
								Змінити картинку
							</MuiButton>
						</Box>
					</Box>

					{/*COVER EN*/}
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{display: 'none'}}
							ref={ImageInputRefEN}
							onChange={handleUploadEN}
						/>
						{openEn && <ImageCropperModal open={openEn} setOpen={setOpenEn} selectedFile={blobEN} setBlob={setBlobEN}/>}
						{blobEN && <img
							style={{width: '100%', height: '100%'}}
							src={URL.createObjectURL(blobEN)}
							alt='photo'
						/>}

						{blobError && (
							<Alert severity='error' sx={{mb: 2}}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (ImageInputRefEN.current) {
										ImageInputRefEN.current.click()
									}
								}}
							>
								Змінити картинку
							</MuiButton>
						</Box>
					</Box>
				</Box>
			</SimpleForm>
		</Create>
	)
}

export default EventsCreate
