import {useEffect, useRef, useState} from "react";
import ReactCrop, {Crop} from "react-image-crop";
import {Box, Button as MuiButton, Modal, Typography} from "@mui/material";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	selectedFile: Blob | null;
	setBlob?: (blob: Blob | null) => void;
	setVideo?: (video: any) => void;
	language?: 'uk' | 'en';
}

export const ImageCropperModal = (props: Props) => {
	const {open, setOpen, selectedFile, setBlob, setVideo, language} = props;

	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
	const imgRef = useRef<HTMLImageElement | null>(null);
	const [croppedImage, setCroppedImage] = useState<Blob | null>(null);
	const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);

	console.log('selectedFile', selectedFile);

	useEffect(() => {
		if (completedCrop && imgRef.current) {
			getCroppedImg();
		}
	}, [completedCrop]);

	useEffect(() => {
		if (croppedImage) {
			const objectUrl = URL.createObjectURL(croppedImage);
			setCroppedImageUrl(objectUrl);

			return () => {
				if (objectUrl) {
					URL.revokeObjectURL(objectUrl);
				}
			};
		}
	}, [croppedImage]);

	const getCroppedImg = async () => {
		if (!completedCrop || !imgRef.current) return null;

		const {naturalWidth, naturalHeight} = imgRef.current;

		const scaleX = naturalWidth / imgRef.current.width;
		const scaleY = naturalHeight / imgRef.current.height;

		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		if (!ctx) return null;

		canvas.width = completedCrop.width;
		canvas.height = completedCrop.height;

		ctx.drawImage(
			imgRef.current,
			completedCrop.x * scaleX,
			completedCrop.y * scaleY,
			completedCrop.width * scaleX,
			completedCrop.height * scaleY,
			0,
			0,
			completedCrop.width,
			completedCrop.height
		);

		return new Promise<Blob | null>((resolve) => {
			canvas.toBlob((blob) => {
				resolve(blob);
			}, 'image/jpeg');
		});
	};

	const handleSave = async () => {
		const blob = (await getCroppedImg()) as Blob;
		if (blob && setBlob) {
			setBlob(blob);

			setCroppedImageUrl(null);
			setOpen(false);
		}

		if (blob && setVideo) {
			if (language === 'uk') {
				setVideo((prev: any) => ({...prev, snippet: URL.createObjectURL(blob), snippetBlob: blob}));

				setCroppedImageUrl(null);
				setOpen(false);
			}

			if (language === 'en') {
				setVideo((prev: any) => ({...prev, snippetEN: URL.createObjectURL(blob), snippetBlobEN: blob}));

				setCroppedImageUrl(null);
				setOpen(false);
			}
		}
	};

	return (
		<Modal sx={{p: 4}} open={open} onClose={() => setOpen(false)}>
			<Box
				sx={{
					width: '100%',
					maxHeight: '100%',
					bgcolor: 'background.paper',
					borderRadius: 2,
					boxShadow: 24,
					p: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 3,
					overflow: 'auto',
				}}
			>
				<Typography variant="h5">Обрізати зображення</Typography>
				{selectedFile && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%',
							overflow: 'auto',
							border: '1px solid #e0e0e0',
							borderRadius: '8px',
							padding: '8px',
							boxSizing: 'border-box',
						}}
					>
						<ReactCrop
							crop={crop}
							onChange={(newCrop) => setCrop(newCrop)}
							onComplete={(c) => setCompletedCrop(c)}
						>
							<img ref={imgRef} src={URL.createObjectURL(selectedFile)} alt="cropped image"/>
						</ReactCrop>
					</Box>
				)}
				<MuiButton onClick={handleSave} variant="contained" color="primary">
					Зберегти
				</MuiButton>
			</Box>
		</Modal>
	);
};
